<template>
  <div class="w-full h-screen background sm:h-full md:h-screen">
    <navbar></navbar>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <loading v-if="ready"></loading>

    <div class="container mx-auto mt-5 max-w-10 z-10">
      <div
        class="grid sm:grid-cols-2 sm:gap-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-3 md:gap-2 xl:grid-cols-4 xl:gap-4"
        v-if="exams.length > 0"
      >
        <div
          class="m-6 examCerceve"
          v-for="(exam, i) in exams"
          :key="i"
          :class="`${initExam(exam) ? 'card-open' : 'card-close'}`"
        >
          <h3
            class="text-lg font-bold mb-3 float-left w-full"
            :class="`${initExam(exam) ? 'text-white' : 'text-white'}`"
          >
            <img src="../assets/book.png" class=" inline-block mr-2" alt="" />
            {{ exam.name }}
          </h3>

          <button
            class="btn-go-exam examCerceve2"
            v-if="exam.ready === true"
            :class="`${exam.ready === false ? 'btn-gray' : ''}`"
            @click="goExam(exam)"
          >
            Go to Exam
          </button>
        </div>
      </div>

      <div v-if="modalReady" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative my-6 mx-auto w-full" style="height:100%;">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-1 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-2xl my-auto ml-2 font-semibold">
              {{this.selectedExam.name}}
            </h3>
            <!-- <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 float-right" type="button" v-on:click="toggleModal()">
              Kapat
            </button> -->
            <button class="p-4 ml-auto bg-red-500 border-0 text-white float-right text-xl leading-none font-semibold  focus:outline-none" v-on:click="toggleModal()">
             Close
            </button>
          </div>
          <!--body-->
          <div class="relative p-2 flex-auto">
            <iframe :src="selectedExam.url" class="w-full h-screen"></iframe>
          </div>
          <!--footer-->
          <!-- <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">

          </div> -->
        </div>
      </div>
    </div>
    <div v-if="modalReady" class="opacity-25 fixed inset-0 z-40 bg-black"></div>


    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar/Navbar.vue";
import loading from "./loading/loading.vue";


export default {
    data() {
    return {
      username: null,
      password: null,
      ready: false,
      modalReady: false,
      selectedExam : [],
      exams:
          [
              {
                name : 'A2 Key 1',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/a2key1/index.html',
                ready : true,
              },
              {
                name : 'A2 Key 2',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/a2key2/index.html',
                ready : true,
              },
              {
                name : 'A2 Key 3',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/a2key3/index.html',
                ready : true,
              },
              {
                name : 'A2 Key 4',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/a2key4/index.html',
                ready : true,
              },
              {
                name : 'FCE 1',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/fce1/index.html',
                ready : true,
              },
              {
                name : 'FCE 2',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/fce2/index.html',
                ready : true,
              },
              {
                name : 'FCE Preliminary 1',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/fcepreliminary1/index.html',
                ready : true,
              },
              {
                name : 'FCE Preliminary 2',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/fcepreliminary2/index.html',
                ready : true,
              },
              {
                name : 'Preliminary 1',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/preliminary1/index.html',
                ready : true,
              },
              {
                name : 'Preliminary 2',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/preliminary2/index.html',
                ready : true,
              },
              {
                name : 'Preliminary 3',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/preliminary3/index.html',
                ready : true,
              },
              {
                name : 'Preliminary 4',
                url : 'https://storage.googleapis.com/mockexam-storage/dogav2/preliminary4/index.html',
                ready : true,
              }
          ]

    };
  },
  components: { Navbar, loading },
  methods: {
      async goExam(attr){
          this.modalReady = !this.modalReady;
          this.selectedExam = await attr;
        //   console.log(attr);
      },
      toggleModal(){
          this.selectedExam = [];
          this.modalReady = !this.modalReady;
        //   console.log(attr);
      },
      initExam(attr){
          return true;
      }
  }
}
</script>

<style>

</style>
