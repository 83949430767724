import Vue from 'vue';
import Router from 'vue-router';
import store from "./store/index"
// import VueRouter from 'vue-router'

import Home from "./components/Home.vue"
import Login from "./components/Login.vue"
import Sinavlar from "./components/Sinavlar.vue"
import UserDetail from "./components/UserDetail.vue"
import StudentList from "./components/teacher/studentList.vue"
import ReportStudent from "./components/teacher/ReportStudent.vue"
import SinavEkrani from "./components/SinavEkrani.vue"
import ExamsTeacher from "./components/ExamsTeacher.vue"


// import store from "./store/index"

// 0. If using a module system (e.g. via vue-cli), import Vue and VueRouter
// and then call `Vue.use(VueRouter)`.
Vue.use(Router);

const router = new Router({
    // mode: "history",
    routes: [
      {
        name: 'login',
        path: '/login',
        component: Login,
      },
      {
        name: 'home',
        path: '/',
        component: Home,
      },
      {
        name: 'sinavlar',
        path: '/sinavlar',
        component: Sinavlar,
      },
      {
        name: 'user-detail',
        path: '/user-detail',
        component: UserDetail,
      },
      {
        name: 'student-list',
        path: '/student-list',
        component: StudentList,
      },
      {
        name: 'exams',
        path: '/exams',
        component: ExamsTeacher,
      },
      {
        name: 'sinav-ekrani',
        path: '/sinav-ekrani',
        component: SinavEkrani,
      },
      {
        name: 'report-student',
        path: '/report-student/:student_id?/:exam_id?',
        component: ReportStudent,
      },
    //   {
    //     name: 'teacher',
    //     path: '/addTeacher',
    //     component: teacherPage,
    //   },
    //   {
    //     name: 'teacherList',
    //     path: '/teacherList',
    //     component: teachersListPage,
    //   },
    ],
  });
  
  export default router;


  router.beforeEach((to, from, next) => {
    const auth = store.getters.isAuthenticated;
    // console.log("auth",auth)

    if (auth === null && to.name !== 'login') {
      router.push('/login').catch(()=>{});
      next();
    }
    next();
  });

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
// const router = new VueRouter({
//   routes // short for `routes: routes`
// })

// 4. Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
// const app = new Vue({
//   router
// }).$mount('#app')

// Now the app has started!