<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="h-full afterParca border-b border-white">
      <div class="relative items-center justify-between h-full">
        <button
          @click="saveExit"
          class="btn-close float-right right-6 rounded-2xl my-2 mx-2 w-48 h-12 border-4 border-white-500 align-middle"
        >
          Save & Exit
        </button>
        <!-- <button @click="goHome" class="btn-close right-6 rounded-2xl top-4 w-16 h-16 border-4 border-purple-500">X</button> -->
      </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    saveExit() {
      Swal.fire({
        title: "Are you sure?",
        text: "If you log out, your exam will be over!",
        icon: "warning",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        // hideClass: {
        //     popup: 'animate__animated animate__fadeOutUp'
        // },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push("/");
        }
      });
    },
  },
};
</script>
<style>
  afterParca:after{
    content: "";
    position: relative;
    background: url(../../assets/section_notch_top.png);
    background-repeat: repeat-x;
    display: block;
    bottom: -7px;
    width: 100%;
    height: 7px;
    z-index: 2;
  }
</style>

