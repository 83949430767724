import authService from '@/services/auth';

export default {
  state: {
    username: '',
    password: '',
  },
  getters: {},
  mutations: {
    // setUserInformation(state, payload) {
    //   state.username = payload.username;
    //   state.password = payload.password;
    // },
  },
  actions: {
    login(context, payload) {
      return new Promise((resolve, reject) => {
        authService
          .login(payload.username, payload.password)
          .then(async (res) => {
            if (res.success) {
              // console.log("res.success",res.success)
              // context.commit('setUserInformation', {
              //   username: payload.username,
              //   password: payload.password,
              // });
              context.dispatch('getUserDetails');
              resolve(res);
            } else {
              resolve(res);
            }
          })
          .catch(err => reject(err));
      });
    },
  },
};
