<template>
  <div class="w-full h-screen background sm:h-full md:h-screen">
    <navbar></navbar>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <loading v-if="ready"></loading>

    <div class="container mx-auto mt-5 max-w-10 z-10">
      <div
        class="grid sm:grid-cols-2 sm:gap-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-3 md:gap-2 xl:grid-cols-4 xl:gap-4"
        v-if="exams.length > 0"
      >
        <div
          class="m-6 examCerceve"
          v-for="(exam, i) in exams"
          :key="i"
          :class="`${initExam(exam) ? 'card-open' : 'card-close'}`"
        >
          <h3
            class="text-lg font-bold mb-3 float-left w-full"
            :class="`${initExam(exam) ? 'text-white' : 'text-white'}`"
          >
            <img src="../assets/book.png" class=" inline-block mr-2" alt="" />
            {{ exam.exam_name }}
          </h3>

          <p
            class="p-5 sm:p-1"
            :class="`${initExam(exam) ? 'text-white' : 'text-white'}`"
          >
            {{ exam.start_date | formatDate }} -
            {{ exam.end_date | formatDate }} <br />
          </p>
          <!-- <hr> -->
          <button
            class="btn-go-exam examCerceve2"
            v-if="initExam(exam)"
            :class="`${exam.ready === false ? 'btn-gray' : ''}`"
            @click="goExam(exam, user_id)"
          >
            Go to Exam
          </button>

          <button
            class="btn-finished-block opacity-80"
            v-else-if="exam.page_total === exam.scores.length"
          >
            <!-- {{ exam.errMsg != "" ? exam.errMsg : "Closed" }} -->
            {{ exam.errMsg != "" && exam.errMsg ? exam.errMsg : "Finished" }}
          </button>
          <button disabled class="btn-gray examCerceve2" v-else>
            <!-- {{ exam.errMsg != "" ? exam.errMsg : "Closed" }} -->
            {{ exam.errMsg != "" && exam.errMsg ? exam.errMsg : "Closed" }}
          </button>
          <div v-if="exam.page_total === exam.scores.length">
              <h3 class="text-white"> <b>Score : </b> {{ Math.floor(exam.avg_score)}}</h3>
          </div>
        </div>
      </div>
      <div v-else class="mx-10 mt-48">
        <div class="text-center h-auto font-medium bg-red-500 text-white w-full p-5">
          <h1 class="text-black font-extrabold text-lg"> You don't have an exam! </h1>
          <!-- <p class=" text-lg">Tanımlı bir sınav bulunamadı!</p> -->
        </div>
      </div>
    </div>
    <!-- <img src="../assets/bg_footer.png" class="bottom-0 absolute w-full z-0" alt=""> -->
  </div>
</template>

<script>
import Navbar from "./Navbar/Navbar.vue";
import loading from "./loading/loading.vue";
import authServices from "../services/auth";
import studentServices from "../services/student";
import _ from "lodash";
// import CryptoJS from 'crypto-js'
import { mapGetters } from "vuex";
// import store from "vuex";
// import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      user_id: null,
      organization: null,
      exams: [],
      serverSettings: null,
      ready: false,
    };
  },
  components: { Navbar, loading },
  async mounted() {
    // const secret = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
    this.ready = true;
    const user = await authServices.getUser(); // Kullanıcı bilgilerini getir
    const sett = await authServices.getSettings(); // Global Tarih bilgisini getir
    const exams = await studentServices.getExams(); // Öğrenciye tanımlı exam lar gelsin

    // console.log("user",user.data.role_type);

    this.serverSettings = sett;
    this.user_id = user.data.user_id;
    this.organization = user.data.organization;
    this.exams = exams.exams;
    // if(user.role_type === 'student'){
    //   this.getScore();
    // }
    this.ready = false;
  },
  methods: {
    goExam(attr, user_id) { //Sınava giriş ekranına git!
      const page_name = attr.scores[attr.scores.length - 1]
        ? attr.scores[attr.scores.length - 1].page_name
        : 0;

      const routerObj = {
        name: "sinav-ekrani",
        params: {
          url: attr.exam_url,
          user_id: user_id,
          exam_id: attr.exam_id,
          organization: this.organization,
          page_name: page_name,
        },
      };
      this.$router.push(routerObj);
    },
    initExam(exam) { //Exam tarih & Exam Bitmiş mi ? kontrolleri
      if (
        exam.start_date < this.serverSettings.data &&
        exam.end_date > this.serverSettings.data &&
        exam.page_total != exam.scores.length
      ) {
        // console.log(exam);
        return true;
      }
      // if (
      //   new Date(exam.start_date).getTime() < this.tarih &&
      //   new Date(exam.end_date).getTime() > this.tarih &&
      //   exam.page_total != exam.scores.length
      // ) {
      //   return true;
      // }
    },
    getScore(){
      // console.log('getScore run', this.exams);
      for(const item of this.exams){
        item.totalListening = item.scores.filter(item => item.skill_name === "Listening").length;
        item.totalRw = item.scores.filter(item => item.skill_name === "Reading and Writing").length;
        item.ListeningScore = 0;
        item.RwScore = 0;
        item.scores.forEach(element => {
          element.score = element.page_score * 100;
          if(element.skill_name === "Listening"){
            item.ListeningScore += element.score;
          }
          if(element.skill_name === "Reading and Writing"){
            item.RwScore += element.score;
          }
        });
        item.ListeningAvg = item.ListeningScore / item.totalListening;
        item.RwAvg = item.totalRw != 0 ? item.RwScore / item.totalRw : 0;
        item.totalScore = (item.ListeningAvg + item.RwAvg) / 2;
      }
    }
  },
  computed: {
    ...mapGetters(["getRole"]),
  },
  beforeMount() {
    this.ready = true;
    if (this.getRole === "teacher") {
      //Kullanıcı teacher ise student list sayfasına yönlendir.
      this.$router.push("/student-list");
      return;
    }
    this.ready = false;
  },
  filters: {
    formatDate(value) {
      if (value) {
        // return "test"
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,500&display=swap"); */
.background {
  background: #e4f9ff url(../assets/page_bg.jpg) repeat-x top;
  /* height: 100%; */
}
.examCerceve {
  background: #d21f22;
  border: 1px solid white;
}

.card-open:hover {
  background-color: #b51d1f;
  box-shadow: 1px 1px 30px black;
}

/* .examCerceve2{
  background:rgba(7,143,203,.8);
} */
/* * {
  font-family: "Lora", serif;
} */

nav:after {
  content: "";
  position: relative;
  background: url(../assets/section_notch_top.png);
  background-repeat: repeat-x;
  display: block;
  bottom: -7px;
  width: 100%;
  height: 7px;
  z-index: 2;
}



.btn-go-exam {
  background-color: #000;
}
</style>
