<template>
  <div class="mx-auto">
    <navbar></navbar>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="lg:m-32 xl:m-32 m-16 bg-white shadow overflow-hidden sm:rounded-lg align-middle">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          User Detail
        </h3>
        <!-- <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{getUserDetail.user_id}}
        </p> -->
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">
              Full name
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{getUserDetail.first_name + " " + getUserDetail.last_name}}
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">
              Rol
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{getUserDetail.role_type}}
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">
              E-Posta
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{getUserDetail.email}}
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">
              Sınıf
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{getUserDetail.organization[0].name}}
            </dd>
          </div>


        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Navbar from "./Navbar/Navbar.vue";

export default {
  components: { Navbar },
  data() {
    return {
      // organizations = [],
    };
  },
  computed: {
    ...mapGetters(["getUserDetail"]),
  },
};
</script>
<style></style>
