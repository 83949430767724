<template>
<div>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-40 w-auto" src="../assets/myeduEXAMSS.png">
        <!-- <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            start your 14-day free trial
          </a>
        </p> -->
      </div>
      <form class="">
        <!-- <input type="hidden" name="remember" value="true"> -->
        <div class="rounded-md shadow-sm -space-y-px mb-6">
          <div>
            <label for="username" class="sr-only">Username</label>
            <input v-model="username" name="username" type="text" autocomplete="username" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Username">
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
          </div>
        </div>

        <div @click.prevent="login()">
          <button class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white girisBtn focus:outline-none focus:ring-2 focus:ring-offset-2">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: lock-closed -->
              <svg class="h-5 w-5 girisBtnLock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Login
          </button>
        </div>
      </form>
    </div>
    <loading v-if="ready"></loading>
  </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
// import services from '@/services/teacher';
import adminServices from '@/services/admin';
import authServices from '@/services/auth';
import loading from '@/components/loading/loading.vue';
import tools from '../tools';
import _ from 'lodash';

export default {
  data() {
    return {
      username: null,
      password: null,
      ready: false,
    };
  },
  components: {
    loading,
  },
  computed: {
  },
  async beforeMount() {
    this.autoLogin();
  },
  methods: {
    async login() {
      this.ready = true;
    //   const admin = await adminServices.getAuth(this.username, this.password);
    //   if (admin._id) {
    //     this.setLoginState("Admin", admin._id);
    //     return;
    //   }
      const user = await authServices.login(this.username, this.password);
      if (user.data.token) {
        await authServices.getUser();
        this.setLoginState(user.data.token);
        // const userDetail = await adminServices.getUser();
        // console.log("userDetail",user.data.token);

        return;
      }else{

        Swal.fire(
          'Error!',
        'Username or Password is incorrect',
        'error'
      )
      }
      this.ready = false;
    },
    async autoLogin() {
      this.ready = true;
      const arr = this.$route.query;
      let jsonArr = {};
       if (_.isEmpty(arr) === false) {

         const user = await authServices.login(atob(arr.username), atob(arr.password));
         if (user.data.token) {
           await authServices.getUser();
           this.setLoginState(user.data.token);
           // const userDetail = await adminServices.getUser();
           // console.log("userDetail",user.data.token);

           return;
         }
           Swal.fire(
             'Hata!',
             'Kullanıcı Adı veya Şifre yanlış',
             'error'
           );
       }

      this.ready = false;


    },
    setLoginState(token) {
        this.$store.commit("setAuthenticate", true);
        this.$store.commit("setToken", token);
        // this.$store.commit("setRole", role);
        //   this.$store.commit("setTeacherId", id);
        // this.$store.commit("setExpireTime", Date.now() + 3600000);

        this.$router.push("/");
        //   if (role === "Admin") {
        //     this.$router.push("/adminHome");
        //   } else {
        //     this.$router.push("/");
        //   }
    }
  }
}
</script>

<style>
  .girisBtn {
    background: #d21f22;
  }
  .girisBtnLock {
    color: #fff;
  }
</style>
