import uesLms from '../services';
import tools from '../tools';
import store from '../store';
// import config from '../config';

export default {
  async login(username, password) {
    try {
      const res = await uesLms().post('authenticate', {
        email: username,
        password,
        api_key: 'v90CND1jPOUK174qUj4aGHn4sxTQsJO304ES4f6NlS7ll3Pp',
      });

      if (res.message === 'Request failed with status code 401') {
        return {
          success: false,
        };
      }
      await tools.cookie.set("access_token", res.data.token);
      // store.state.auth.username = username;
      // store.state.auth.password = password;
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getUser() {
    // await authService.refreshToken();
    const res = await uesLms().post('user');
    store.commit("setUserData",res.data.data);
    store.commit("setRole",res.data.data.role_type)
    // console.log("user",res.data.data);
    return res.data;
  },
  async getSettings() {
    // await authService.refreshToken();
    const res = await uesLms().get('settings/datetime');
    // store.commit("setUserData",res.data.data);
    // console.log("user",res.data.data);
    return res.data;
  },
  async refreshToken() {
    try {
      console.log("refff",store.state);
      const username = store.state.auth.username;
      const password = store.state.auth.password;
      const res = await uesLms().post('authenticate', {
        email: username,
        password,
        api_key: 'v90CND1jPOUK174qUj4aGHn4sxTQsJO304ES4f6NlS7ll3Pp',
      });
      await tools.cookie.set("access_token", res.data.token);
      console.log("f5token",res.data.token);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      console.log("burası");

      return {
        success: false,
        data: error.message,
      };
    }
  },
};
