<template>
  <div class=" w-full h-screen">
    <loading v-if="ready"></loading>
    <navbar></navbar>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="container sm:mx-auto px-4 w-full h-60 sm:h-32">
      <div class="flex-col mt-4 block w-full h-8 m-4">
        <select
          name="class_id"
          v-model="selectClass"
          class=" w-48 h-12 m-5 bg-white text-black border border-blue-700 rounded-md text-center float-left"
        >
          <option value="">Choose Class...</option>
          <option
            class="m-4"
            v-for="(classList, i) in organizations"
            :key="i"
            :value="classList.id"
          >
            {{ classList.name }}
          </option>
        </select>
        <select
          v-show="selectClass"
          name="exam_id"
          v-model="selectExam"
          class=" w-48 h-12 m-5 bg-white text-black border border-blue-700 rounded-md text-center float-left"
        >
          <option value="">Choose Exam...</option>
          <option
            class="m-4"
            v-for="(exam, i) in examList"
            :key="i"
            :value="exam.exam_id"
          >
            {{ exam.name }}
          </option>
        </select>
        <button
          @click="goReport"
          :disabled="selectClass === '' || selectExam === ''"
          class="primary-color text-white float-left w-32 h-12 m-5 rounded-md ml-3 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-500 transform hover:scale-110 motion-reduce:transform-none hover:cursor-pointer "
        >
          Get Report
        </button>
        <button @click="exportToExcel()" v-if="items != null && items.length > 0"
          class="bg-green-500 text-white float-left w-32 h-12 m-5 rounded-md ml-3 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-500 transform hover:scale-110 motion-reduce:transform-none hover:cursor-pointer "
        >
            Export to Excel
        </button>
      </div>
    </div>

    <!-- {{organization_grades}} -->

    <div
      class="md:container md:mx-auto float-left md:float-none"
      v-if="items != null && items.length > 0"
    >
      <hr />
      <div class="flex flex-col mt-3">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200" id="sheetjs">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Avg.
                    </th>
                    <!-- <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Listening
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Reading & Writing
                    </th> -->
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      v-for="(item, index) in titles" :key="index"
                    >
                      {{item}}
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(item, index) in items" :key="index">
                    <td
                      class="px-6 py-3 text-left text-lg font-semibold text-blue-500 tracking-wider"
                    >
                      {{ item.student_name }}
                    </td>
                    <td
                      class="px-6 py-3 text-left text-lg font-semibold text-yellow-500 tracking-wider"
                    >
                      {{ item.average }}%
                      <!-- <div class="text-sm text-gray-500">Optimization</div> -->
                    </td>
                    <!-- <td
                      class="px-6 py-3 text-left text-lg font-semibold text-red-500 tracking-wider"
                    >
                      {{ item.listening }}%
                    </td>
                    <td
                      class="px-6 py-3 text-left text-lg font-semibold text-green-500 tracking-wider"
                    >
                      {{ item.reading_writing }}%
                    </td> -->
                    <td
                      scope="col"
                      class="px-6 py-3 text-left text-lg font-semibold uppercase tracking-wider"
                      :class="index % 2 === 0 ? 'text-red-500' : 'text-green-500'"
                      v-for="(i, index) in titles" :key="index"
                    >
                      {{test(item.skills,i)}}%
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <!-- <router-link class="primary-color hover:bg-green-300 hover:shadow-md hover:text-white px-5 py-3 rounded-md text-base font-medium" :to="{ name: 'report-student', params: { exam_id: selectExam, student_id:item.student_id } }">Detail</router-link> -->
                      <button
                        class="primary-color hover:bg-green-300 hover:shadow-md hover:text-white px-5 py-3 rounded-md text-base font-medium"
                        @click="modalOpen(item)"
                      >
                        Detail
                      </button>

                    </td>
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
              <modal
                        @isModal="isModal = false"
                        :isModal="isModal"
                        :exam_id="this.selectExam"
                        :student_id="this.selectStudent"
                        :titles="this.titles"
                      ></modal>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10 md:container md:mx-auto px-12" v-else-if="items != null">
      <div
        class="text-center font-bold text-lg bg-white text-red-600 border border-black rounded-lg p-5"
      >
        There were no results!
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "../Navbar/Navbar.vue";
import loading from "../loading/loading.vue";
import Modal from "../Modal/Modal.vue";
import teacherServices from "@/services/teacher";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  data() {
    return {
      ready: false, //loading için tanımlı parametre
      items: null, //Öğrenci listesi için tanımlı parametre
      organizations: null, //Sınıflar için tanımlı parametre
      examList: "", // Exam Listelemesi için tanımlı parametre
      selectClass: "", //Seçili Sınıf
      selectExam: "", //Seçili exam
      selectStudent: "",
      isModal: false, //Öğrenci detayı için modal parametresi
      titles: [],
    };
  },
  components: {
    navbar,
    loading,
    Modal,
  },
  methods: {
    gelen(sonuc) {
      // Modal componentinin emit değerinden gelen değeri alma
      this.isModal = sonuc;
    },
    organization_grades() {
      //Gelen organizationlarda Sınıfları filtreleme
      // console.log("org", this.organizations);
      return this.organizations != null
        ? this.organizations.filter((i) => i.type === "grade")
        : this.organizations;
    },
    async goReport() {
      //sınıf Raporunu getir
      this.ready = true;
      let reportClass = await teacherServices.getClassReport(
        this.selectClass,
        this.selectExam
      );
      this.items = reportClass.results;
      this.items.forEach(element => {
        element.skills
      });
      this.titles = _.maxBy(reportClass.results, obj => obj.skills.length ? obj.skills.length : 0);
      this.titles = Object.keys(this.titles ? this.titles.skills : {});
      // console.log('test',_.maxBy(reportClass.results, obj => obj.skills ? obj.skills.length : 0).skills);

      this.ready = false;
      // this.items = [{id : 1, title: this.selectClass+"test", author:"gradess",views:1000}];
    },
    async modalOpen(item) {
      // console.log('isModal', this.isModal);
      // this.selectExam = item.exam_id;
      this.selectStudent = item.student_id;
      this.isModal = true;
    },
    exportToExcel() {
      if (this.items == null) {
        this.$swal({
          type: 'error',
          title: 'Error',
          text: 'There is no report!',
          // confirmButtonText: 'Okey',
        });
        return;
      }
      let excelTitle = 'Report - ';
      // for (const item of this.selectedCampuses) {
      //   excelTitle += item.name;
      //   excelTitle += ' - ';
      // }
      // excelTitle += `Grade ${this.selectedGrade} - `;
      excelTitle += new Date().toLocaleDateString();
      excelTitle += '.xlsx';
      const tbl = document.getElementById('sheetjs');
      const ws = XLSX.utils.table_to_sheet(tbl);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, excelTitle);
    },
    test(val, val2){
      const skills = val2.toLowerCase();
      return val[val2] ? val[val2] : 0;
    }
  },
  async mounted() {
    this.selectClass = "";
    this.selectExam = "";

    // const examList = await teacherServices.getExamList();

    this.organizations = this.getUserDetail.organization.filter(
      (i) => i.type === "grade"
    );
    // this.examList = examList.exams;

    // console.log("examList", this.examList.exams);
    // const test = await teacherServices.getList();
    // console.log(test);
  },
  computed: {
    ...mapGetters(["getUserDetail"]),

    // items(){
    //     if(this.items){
    //         this.ready = true;
    //     }
    // }
  },
  watch: {
    async selectClass(val) {
      this.items = null;
      const examList = await teacherServices.getExamList(val);
      this.examList = examList.exams;
      if (this.examList.length === 1) {
        this.selectExam = this.examList[0].exam_id
        this.goReport()
      }
    },
    selectExam(val) {
      this.items = null;
    },
    isModal(val) {
      // console.log('isModal', val);
      // this.isModal = val;
    },
  },
};
</script>
<style></style>
