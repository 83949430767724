<template>

<div>    <!-- This example requires Tailwind CSS v2.0+ -->
<loading v-if="ready"></loading>
<div class="fixed z-10 inset-0 mx-2 md:mx-20 overflow-y-auto" v-if="isModal === true && ready === false">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->


    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="container mx-auto px-4 mt-10">
        <div class="grid grid-cols-1 " :class="titles.length === 3 ? 'lg:grid-cols-3' : 'lg:grid-cols-2'">
            <div class="w-full border-r-2 border-white h-full py-2" v-for="(itemm, x) in detailReport" :key="x">
               <!-- {{student_id}} -->
               <h2 class=" font-extrabold my-5 text-xl text-center">{{x}}</h2>
                <div class="grid grid-cols-4 border border-gray-900  my-2 mx-2" v-for="(item,i) in itemm" :key="i">
                    <div class=" table align-middle h-full font-bold border-r border-black bg-blue-400 text-white text-center">
                         <!-- px-12 py-10 md:py-10 md:px-12 -->
                        <div class="align-middle table-cell">{{item.name}}</div>
                    </div>
                    <div class=" col-span-3 bg-white">

                        <div class="relative pt-1 px-3">
                        <div class="flex mb-2 items-center justify-between">
                            <div>
                            <!-- <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200">
                                Score
                            </span> -->
                            </div>
                            <div class="text-right">
                            <span class="text-xs font-semibold inline-block text-yellow-600">
                                {{item.score}}%
                            </span>
                            </div>
                        </div>
                        <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-yellow-200">
                            <div :style="{width: item.score + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"> </div>
                        </div>
                        </div>
                        <p class=" w-full px-3 py-3 break-words whitespace-normal">
                        {{item.desc}}
                        </p>

                        </div>
                </div>
            </div>
        </div>
        <table id="sheetjsdetail" style="display:none;">
              <span v-for="(itemm, x) in detailReport" :key="x">
              <tr>

              <th>
                  {{x}}
              </th>
              </tr>
              <tr v-for="(item,i) in itemm" :key="i">
                <td>
                    {{item.name}}
                </td>
                <td>
                    {{item.score}}
                </td>
              </tr>
              </span>
        </table>
        </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="closeClick()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          Close
        </button>
        <button @click="exportToExcel()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
          Export to Excel
        </button>
      </div>
    </div>
  </div>
</div>
</div>
</template>
<script>
import Loading from '../loading/loading.vue';
import teacherServices from '@/services/teacher';
import XLSX from 'xlsx';


export default {
    data(){
        return{
            // student_id : null,
            result : [],
            ready:false,
            listening: null,
            reading_writing: null,
            writing: null,
            reading: null,
            detailReport: null,
        }
    },
    props: ['isModal','student_id','exam_id','titles'],
    methods:{
        closeClick(){
            this.$emit('isModal',false);
        },
        goStudentList(){
            this.$router.push("/student-list");
        },
        async getDetailReport(){
            this.ready = true; // Loading Başlar


            if(this.student_id && this.exam_id){

                const detailReport = await teacherServices.getStudentReport(this.student_id,this.exam_id);
                if(detailReport.result.length === 0){
                    this.goStudentList()
                }
                this.detailReport = detailReport.result[0];
                this.listening = detailReport.result[0].Listening;
                this.writing = detailReport.result[0].Writing;
                this.reading = detailReport.result[0].Reading;
                this.reading_writing = detailReport.result[0]["Reading and Writing"];
            }else{
                this.goStudentList();
            }


            this.ready = false;
        },
        exportToExcel() {
          if (this.detailReport == null) {
            this.$swal({
              type: 'error',
              title: 'Error',
              text: 'There is no report!',
              // confirmButtonText: 'Okey',
            });
            return;
          }
          let excelTitle = 'ReportDetail - ';
          // for (const item of this.selectedCampuses) {
          //   excelTitle += item.name;
          //   excelTitle += ' - ';
          // }
          // excelTitle += `Grade ${this.selectedGrade} - `;
          excelTitle += this.student_id + '_';
          excelTitle += this.exam_id + '_';
          excelTitle += new Date().toLocaleDateString();
          excelTitle += '.xlsx';
          const tbl = document.getElementById('sheetjsdetail');
          const ws = XLSX.utils.table_to_sheet(tbl);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, excelTitle);
        },
    },
    components:{
      Loading
    },
    async mounted(){

    },
    watch:{
        isModal(val){
            // console.log("değişiklik izlendi",val);
            if(val === true){
                // console.log("student_id",this.student_id);
                this.getDetailReport();
            }

        },
        student_id(val){
            // console.log("değişiklik student_id",val);
        }
    }

}
</script>
<style>

</style>
