<template>
    <div class="background sm:h-full lg:h-screen" v-if="isModel">
        <navbar></navbar>
        <loading v-if="ready"></loading>

        <div class="container mx-auto px-4 mt-10">
        <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="w-full border-r-2 border-white h-full py-2">
               <!-- {{student_id}} -->
               <h2 class=" font-extrabold my-5">Listening</h2>
                <div class="grid grid-cols-4 border border-gray-900  my-2 mx-2" v-for="(item,i) in listening" :key="i">
                    <div class="inline-block align-middle h-full pt-4 font-bold border-r border-black bg-blue-400 text-white">{{item.name}}</div>
                    <div class=" col-span-3 bg-white">                        
                        
                        <div class="relative pt-1 px-5">
                        <div class="flex mb-2 items-center justify-between">
                            <div>
                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200">
                                Score
                            </span>
                            </div>
                            <div class="text-right">
                            <span class="text-xs font-semibold inline-block text-yellow-600">
                                {{item.score}}
                            </span>
                            </div>
                        </div>
                        <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
                            <div :style="{width: item.score + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
                        </div>
                        </div>
                        <p class=" w-full py-3">
                        {{item.desc}}
                        </p>
                        
                        </div>
                </div>
            </div> 
            <div class="w-full border-l-2 border-white bg-blue-200 h-full py-2">
            <!-- {{exam_id}} -->
                <h2 class=" font-extrabold my-5">Reading & Writing</h2>
                <div class="grid grid-cols-4 border border-gray-900 my-2 mx-2" v-for="(item,i) in reading_writing" :key="i">
                    <div class=" align-bottom h-full pt-4 font-bold border-r border-black bg-blue-400 text-white">{{item.name}}</div>
                    <div class=" col-span-3 bg-white">                        
                        
                        <div class="relative pt-1 px-5">
                        <div class="flex mb-2 items-center justify-between">
                            <div>
                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200">
                                Score
                            </span>
                            </div>
                            <div class="text-right">
                            <span class="text-xs font-semibold inline-block text-yellow-600">
                                {{item.score}}
                            </span>
                            </div>
                        </div>
                        <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
                            <div :style="{width: item.score + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
                        </div>
                        </div>
                        <p class=" w-full py-3">
                        {{item.desc}}
                        </p>
                        
                        </div>
                </div>
            </div> 
        </div>
        </div>
    </div>
    
</template>
<script>
import Loading from '../loading/loading.vue';
import navbar from '../Navbar/Navbar.vue';
import teacherServices from '@/services/teacher';

export default {
    data(){
        return{
            // student_id : null,
            result : [],
            ready:false,
            listening: null,
            reading_writing: null,
        }
    },
    components:{
        navbar,
        Loading
    },
    methods:{
        goStudentList(){
            this.$router.push("/student-list");
        }
    },
    props: ['isModal','student_id','exam_id'],
    async mounted(){
        this.ready = true;

        console.log(this.isModal)
        // if(this.$route.params.student_id && this.$route.params.exam_id){
        //     console.log("sa");
        //     this.student_id = this.$route.params.student_id;
        //     this.exam_id = this.$route.params.exam_id;
        //     const detailReport = await teacherServices.getStudentReport(this.student_id,this.exam_id);
        //     console.log("detail",detailReport);
        //     if(detailReport.result.length === 0){
        //         this.goStudentList() 
        //     }
        //     this.listening = detailReport.result[0].Listening;
        //     this.reading_writing = detailReport.result[0]["Reading and Writing"];
        // }else{
        //     this.goStudentList();
        // }
        

        this.ready = false;
    }
}
</script>
<style>

</style>