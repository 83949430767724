<template>
<div>
    <navbar></navbar>
    <div class="container mx-auto px-4">
        <div class="max-w-md mx-auto bg-white rounded-xl shadow-shadow-2xl overflow-hidden md:max-w-2xl m-8 p-12 float-left">
            <div class="md:flex">
                <div class="md:flex-shrink-0">
                    <img class="h-48 w-full object-cover md:w-48" src="../assets/ueslogo.png" alt="Man looking at item at a store">
                </div>
                <div class="p-8">
                <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">February 2021</div>
                    <button class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Go to exam
                    </button>                    <!-- <p class="mt-2 text-gray-500">Getting a new business off the ground is a lot of hard work. Here are five ideas you can use to find your first customers.</p> -->
                </div>
            </div>
        </div>

         <div class="max-w-md mx-auto bg-white rounded-xl shadow-shadow-2xl overflow-hidden md:max-w-2xl m-8 p-12 float-left">
            <div class="md:flex">
                <div class="md:flex-shrink-0">
                    <img class="h-48 w-full object-cover md:w-48" src="../assets/ueslogo.png" alt="Man looking at item at a store">
                </div>
                <div class="p-8 justify-items-end">
                  <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">March 2021</div>
                     <button class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Go to exam
                    </button>
                </div>
            </div>
        </div>

         <div class="max-w-md mx-auto bg-white rounded-xl shadow-shadow-2xl overflow-hidden md:max-w-2xl m-8 p-12 float-left">
            <div class="md:flex">
                <div class="md:flex-shrink-0">
                    <img class="h-48 w-full object-cover md:w-48" src="../assets/ueslogo.png" alt="Man looking at item at a store">
                </div>
                <div class="p-8 justify-items-end">
                   <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">April 2021</div>
                     <button class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Go to exam
                    </button>
                </div>
            </div>
        </div>

         <div class="max-w-md mx-auto bg-white rounded-xl shadow-shadow-2xl overflow-hidden md:max-w-2xl m-8 p-12 float-left">
            <div class="md:flex">
                <div class="md:flex-shrink-0">
                    <img class="h-48 w-full object-cover md:w-48" src="../assets/ueslogo.png" alt="Man looking at item at a store">
                </div>
                <div class="p-8 justify-items-end">
                   <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">May 2021</div>
                     <button class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Go to exam
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>
<script>
import Navbar from './Navbar/Navbar.vue'
// import CryptoJS from 'crypto-js'
import authServices from "@/services/auth"
import {mapActions} from "vuex";

var cryptobject;

export default {
      data(){
          return{
          }
      },
      components: { Navbar },
      async mounted(){
        const user = await authServices.getUser();
        // if(user){
        //     console.log("user",user)
        // }else{
        //     // alert("Oturum zaman aşımına uğradı.Tekrar giriş yapmayı deneyin!");
        //     // this.logout();
        //     // this.$router.push("/login");
        // }
        // console.log("istek",user);
      },
      methods:{
        ...mapActions(['logout']),
        // encrypt: function() {
        //         cryptobject = CryptoJS.AES.encrypt(this.message, this.encryptsecret);
        //         this.encrypted = {
        //         key: cryptobject.key + '', // don't send this
        //         iv: cryptobject.iv + '', // don't send this
        //         salt: cryptobject.salt + '', // don't send this
        //         ciphertext: cryptobject.ciphertext + '', // don't send this
        //         str: cryptobject + '' // send or store this
        //     }
        // }
      },
      computed : {
          	// decrypted: function() {
            //     return (this.encrypted ? CryptoJS.AES.decrypt(this.encrypted.str, this.decryptsecret).toString(CryptoJS.enc.Latin1) : 'fred');
            // }
      }
}
</script>
<style>

</style>