import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  state: {
     
  },
  getters: {
 
      // getExams(state) {
      //     return state.exams;
      // },

  },
  mutations: {
      // setExams(state,payload){
      //     state.exams = payload
      // }
     
  },
  actions: {
    //   initAuth({state, dispatch}) {
        //   let time = Date.now();
        //   console.log("time",time);
        //   console.log("expTime",state.expireTime);
        //   if (time > state.expireTime && state.token != null) {
        //       dispatch("logout");
        //   }
    //   },
     
  }
}
