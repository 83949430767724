import Vue from "vue";
import Vuex from "vuex";
import tools from "../tools"

Vue.use(Vuex);

export default {
  state: {
      isAuthentication: null,
      role: null,
      expireTime: null,
      teacherId: null,
      token: null,
      userDetail: [],
  },
  getters: {
      isAuthenticated(state) {
          return state.isAuthentication
      },
      getToken(state) {
          return state.token;
      },
      getExpireTime(state) {
          return state.expireTime
      },
      getUserDetail(state) {
          return state.userDetail;
      },
      getRole(state) {
          return state.role;
      }
  },
  mutations: {
      setAuthenticate(state, payload) {
          state.isAuthentication = payload
      },
      setToken(state,payload) {
        state.token = payload
      },
      setRole(state, payload) {
          state.role = payload
      },
      setExpireTime(state, payload) {
          state.expireTime = payload
      },
      setUserData(state,payload){
          state.userDetail = payload
      }
     
  },
  actions: {
    //   initAuth({state, dispatch}) {
        //   let time = Date.now();
        //   console.log("time",time);
        //   console.log("expTime",state.expireTime);
        //   if (time > state.expireTime && state.token != null) {
        //       dispatch("logout");
        //   }
    //   },
      logout({commit}) {
          commit("setAuthenticate",null);
          commit("setRole",null);
        //   commit("setExpireTime",null);
        //   commit("setTeacherId",null);
          commit("setToken",null);
          commit("setUserData",null);
          tools.cookie.set("access_token", "", 0);
      }
  }
}
