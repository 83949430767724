import api from './index';

export default {
  async getExams(){
    const res = await api().post('exams/student');
    // console.log(res);
    return res.data;
  },
  async deleteStudent(username, password) {
    const res = await api().post('student/remove', {
      username,
      password,
    });
    return res.data;
  },
  async resetStudent(username, password) {
    const res = await api().post('student/reset', {
      username,
      password,
    });
    return res.data;
  },
};
