import axios from 'axios';
import tools from '../tools';
import router from '../router';
import store from '../store';

const instance = axios.create();


instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  // config.baseURL = 'http://localhost:8028/';
  let token = tools.cookie.get("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  config.baseURL = 'https://www.ueslms.com/api/v2/';
  return config;
});




// instance.interceptors.response.use(res => res, err => err);


instance.interceptors.response.use(function (response) { //Gelen Status 401 ise logine yönlendir!
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
    // console.log("test");
    store.dispatch('logout')
    router.push('/login')
  }
  return Promise.reject(error)
})


export default () => instance;