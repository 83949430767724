<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
<nav class="bg-white h-auto">
  <div class="max-w-7xl h-full mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-full items-center justify-between">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button @click="mobileMenuToggle" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <!-- Icon when menu is closed. -->
          <!--
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <!-- Icon when menu is open. -->
          <!--
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="flex-1 flex items-center justify-left sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center ml-14 sm:ml-0">
          <img class="block lg:hidden h-20 w-auto mt-2" src="@/assets/myeduEXAMSS.png" alt="MockExam">
          <img class="hidden lg:block h-20 w-auto mt-2" src="@/assets/myeduEXAMSS.png" alt="MockExam">
        </div>
        <div class="hidden sm:block sm:ml-6">
          <div class="flex space-x-4">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <router-link v-if="getRole === 'student'" class="px-3 py-2 mt-5 rounded-md text-sm font-medium sinavlarim-link" to="/">My Exams</router-link>
            <!-- <router-link v-if="getRole === 'student'" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" to="/sinavlar">Sınavlar</router-link>  -->
            <router-link v-if="getRole === 'teacher'" class=" px-3 py-2 mt-5 rounded-md text-sm font-medium sinavlarim-link" to="/student-list">Student List</router-link>

            <router-link v-if="getRole === 'teacher'" class=" px-3 py-2 mt-5 rounded-md text-sm font-medium sinavlarim-link" to="/exams">Exams</router-link>
            <!-- <router-link v-if="getRole === 'teacher'" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" to="/sinav-ekrani">Sınava Gir !</router-link>  -->
            <router-link v-if="!isAuthenticated" to="/login" class="text-gray-300 mt-5 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium renkli-link">Login</router-link>
          </div>
        </div>
      </div>
      <div class="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <!-- <button class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"> -->
            <button v-if="isAuthenticated" @click="userMenu = !userMenu" class="p-1 rounded-md px-5 py-2 focus:outline-none user-menu-link">
              {{ getUserDetail.first_name + " " + getUserDetail.last_name }}</button>

          <!-- @click="userMenu = !userMenu" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg> -->
        <!-- </button> -->

       <div class="ml-3 relative">

          <div v-if="userMenu" class="origin-top-right absolute right-0 mt-6 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-10" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <!-- <router-link to="/user-detail" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Profil</router-link> -->
            <a href="#" v-if="isAuthenticated" @click.prevent="logout" class="block px-4 py-2 text-sm logout-link" role="menuitem">Logout</a>

          </div>

          <!-- <div>
            <button class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>

            </button>
          </div> -->

          <!-- <div class="origin-top-left absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu"> -->
            <!-- <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Your Profile</a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a> -->

<!--
              <router-link v-if="getRole === 'student'" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/">Dashboard</router-link> |
              <router-link v-if="getRole === 'student'" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/sinavlar">Sınavlar</router-link>
              <router-link v-if="getRole === 'student'" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/student-list">Öğrenci Listesi</router-link>
              <router-link v-if="!isAuthenticated" to="/login" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Login</router-link>
              <a href="#" v-if="isAuthenticated" @click.prevent="logout" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Logout</a>
          </div> -->


        </div>
      </div>
    </div>
  </div>
  <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
  <div :class="mobileMenu ? 'block': 'hidden'" class="sm:hidden">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <!-- <a href="#" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Dashboard</a>
      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>
      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>
      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a> -->

            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <router-link v-if="getRole === 'student'" class="block px-3 py-2 rounded-md text-base font-medium sinavlarim-link" to="/">My Exams</router-link>
            <!-- <router-link v-if="getRole === 'student'" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" to="/sinavlar">Sınavlar</router-link>  -->
            <router-link v-if="getRole === 'teacher'" class="block px-3 py-2 rounded-md text-base font-medium sinavlarim-link" to="/student-list">Student List</router-link>
            <router-link v-if="getRole === 'teacher'" class="block px-3 py-2 rounded-md text-base font-medium sinavlarim-link" to="/exams">Exams</router-link>
            <!-- <router-link v-if="getRole === 'teacher'" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" to="/sinav-ekrani">Sınava Gir !</router-link>  -->
            <router-link v-if="!isAuthenticated" to="/login" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Login</router-link>
            <!-- <a href="#" v-if="isAuthenticated" @click.prevent="logout" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Logout</a> -->
    </div>
  </div>
</nav>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import authServices from '@/services/auth';
import Swal from "sweetalert2";


export default {
    data(){
        return{
            mobileMenu : false,
            userMenu : false,
        }
    },
    computed:{
      ...mapGetters(["isAuthenticated","getRole","getUserDetail"]),
        // ...mapGetters([])
    },
    async mounted() {
              // this.kontrol();
              // console.log(this.getRole);
              // return user.first_name;

    },
    methods:{

        // ...mapActions(['logout']),
        // kontrol() {
        // this.$store.dispatch('initAuth');
        // },
        mobileMenuToggle(){
            this.mobileMenu = !this.mobileMenu;
        },
        logout() {
           Swal.fire({
                title: 'Are you sure?',
                // text: "Çıkış yaparsanız, kaydedilmemiş verileriniz kaybolacaktır.!",
                icon: 'warning',
                // showClass: {
                //     popup: 'animate__animated animate__fadeInDown'
                // },
                // hideClass: {
                //     popup: 'animate__animated animate__fadeOutUp'
                // },
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes'
                }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.clear();
                    // console.log("localSilindi",localStorage.getItem("vuex"));
                    this.$store.dispatch('logout');
                    this.$router.push('/login');
                }
               })


        }
    },
    beforeAfter : {
        test(){
          console.log("sa");
        }
    }
    // beforeMount:{
    //     // console.log(this.getRole);

    // }
}
</script>
<style>

.sinavlarim-link:hover{
  color:#14b7f1;
  background: #fff;
}

.sinavlarim-link{
  color:#fff;
  background:#14b7f1;
  border:1px solid #14b7f1;
}

.user-menu-link{
   color:#fff;
   background:#14b7f1;
}

.user-menu-link:focus{
   color:#14b7f1;
   background:#fff;
   border:1px solid #14b7f1;
}
.logout-link{
  background: #fff;
  color:#14b7f1;
}


</style>
