import api from './index';

export default {
  async getList(teacherId) {
    const res = await api().post('teacher/list', {
      teacherId,
    });
    return res.data;
  },
  async getExamList(classId){
    const res = await api().post('exams/report/examList', {
      classId
    });
    return res.data;
  },
  async getClassReport(class_id,exam_id){
    const res = await api().post('exams/report/class',{
      class_id,
      exam_id
    });
    return res.data;
  },
  async getStudentReport(student_id,exam_id){
    const res = await api().post('exams/report/student',{
      student_id,
      exam_id
    });
    return res.data;
  },
  async addStudent(student) {
    const res = await api().post('teacher/add', {
      fullname: student.fullname,
      username: student.username,
      password: student.password,
      teacherId: student.teacherId,
      level: student.level,
    });
    return res.data;
  },
};
