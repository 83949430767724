<template>
  <div class="background">
    <navbar-sinav></navbar-sinav>
    <loading v-if="ready"></loading>
    <!-- <navbar></navbar> -->
    <div class="max-h-580 sm:max-h-680">
      <div class="h-screen max-h-580 sm:max-h-680 w-screen">
        <iframe class="sinav-iframe" :src="iframeSrc" frameborder="0"></iframe>
      </div>
      <!-- <iframe src="http://www.edugamestr.com/testmock"  width="1080" height="680" frameborder="0"></iframe> -->
    </div>
  </div>
</template>
<script>
// import Navbar from './Navbar/Navbar.vue'
import authServices from "@/services/auth";
import loading from "./loading/loading.vue";
// import {mapActions} from "vuex";
import tools from "../tools";
import NavbarSinav from "./Navbar/NavbarSinav.vue";
import Swal from "sweetalert2";
import { isMobileOnly } from "mobile-device-detect";

export default {
  data() {
    return {
      iframeSrc: null,
      gelenDegerler: null,
      url: null,
      ready: false,
    };
  },
  components: { loading, NavbarSinav },
  async mounted() {
    //   await authServices.refreshToken();
    if (isMobileOnly) {
      // mobilden girişleri engelliyoruz!
      // console.log("mobile!");
      Swal.fire({
        title: "Warning",
        icon: "info",
        text: "You can enter the exam via a computer or tablet!",
      });
      this.$router.push("/");
    }
    if (this.IsSafari()) {
      // mobilden girişleri engelliyoruz!
      // console.log("mobile!");
      Swal.fire({
        title: "Warning",
        icon: "info",
        text: "Please enter the exam via the Chrome browser!",
      });
      this.$router.push("/");
    }

    // console.log('safff', this.IsSafari());
    // console.log('saff', browserDetect.isSafari);
    // this.IsSafari();

    if (!this.$route.params.url) {
      this.$router.push("/");
    } else {
      let gelenDegerler = this.$route.params; //Route ile gelen parametreler

      const user_id = window.btoa(gelenDegerler.user_id);
      // console.log("tools",tools.cookie);
      let token = window.btoa(tools.cookie.get("access_token"));
      let exam_id = window.btoa(gelenDegerler.exam_id);
      let exam_url = gelenDegerler.url;
      let org_id = window.btoa(gelenDegerler.organization[0].id);
      let page_name = window.btoa(gelenDegerler.page_name);

      // console.log("token",token);
      // console.log("user_id",user_id);
      // console.log("exam_id",window.btoa(gelenDegerler.exam_id));
      // console.log("organization_id",window.btoa(gelenDegerler.organization[0].id));
      //page : 0

      //   this.iframeSrc =  gelenDegerler.url + "?" + token + ";" + user_id + ";" + gelenDegerler.exam_id + ";" + gelenDegerler.organization[0].id + ";" + 0;
      this.iframeSrc =
        exam_url +
        "?" +
        token +
        ";" +
        user_id +
        ";" +
        exam_id +
        ";" +
        org_id +
        ";" +
        page_name;

      // gelenDegerler.url
      // https://storage.googleapis.com/quizmaker-storage/mockexam/index.html
      // console.log(this.iframeSrc);
    }
  },
  methods: {
    goHome() {
      this.ready = true;
      setTimeout(() => {
        this.ready = false;
        this.$router.push("/");
      }, 1000);
    },
    IsSafari() {

      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
          return false;
          // alert("1") // Chrome
        } else {
          return true
          // alert("2") // Safari
        }
      }
      return false;
    }
  },
  beforeMount() {
    // console.log("test",this.$route)
  },
};
</script>
<style scoped>
.sinav-iframe {
  border-color: #f32163;
}
</style>
